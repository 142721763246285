<template>
  <div class="terms">
      <div class="container">
          <div class="row mt-5 mb- 5">
            <div class="col-md-12">

              <div class="shadow-lg p-5 mb-5 bg-white rounded">

                <h3>Terms and conditions for the purchase of insurance policies through the switch platform</h3>

                <div class="mt-5">
                  <ol>
                    <li>
                      Agreement to the Terms And Conditions

                      <p>
                        These are the abridged Terms and Conditions applicable to the registration, access and use of the Switch system (Switch) offered by Qore Insurance Agency Limited (Qore) and of which a resultant virtual account has been opened by you.
                      </p>
                    </li>
                    <li>
                      Acceptance of the Terms and Conditions

                      <p>
                        In order to register for an Switch Account, you must be at least 18 years old, have legal capacity to do so and be a registered and active subscriber with a mobile services provider that Qore has an agreement with for provision of Switch services.
                      </p>
                    </li>
                    <li>
                      Anti-Money Laundering

                      <p>
                        You acknowledge that your use of Switch shall be in accordance with all anti-money laundering laws and Qore shall at all times comply with its obligations under anti-money laundering laws.
                      </p>
                    </li>
                    <li>
                      Fees and other conditions

                      <p>
                        You agree to pay all interest charges and transaction fees, and all other expenses and taxes incurred in connection with your use of Switch. These fees are to be deducted from your payment.
                      </p>
                    </li>

                    <li>
                      Claims Processing

                      <p>
                        In the event of a policy claim, you agree to ensure that all outstanding premiums related to Lipa Pole Pole insurance premium financing are fully paid to the financier prior to submitting supporting claim documentation.
                      </p>
                    </li>
                    <li>
                      Governing Law

                      <p>
                        The Terms and Conditions shall be governed by and construed in accordance with the laws of the Republic of Kenya.
                      </p>
                    </li>
                    <li>
                      Intention to be bound

                      <p>
                        Please accept terms below to acknowledge that you have read and voluntarily accept the Terms and Conditions.
                      </p>
                    </li>
                  </ol>
                </div>


                <div class="row mt-5">
                  <div class="col-md-6">
                      <button @click="goBackToHomepage" class="btn btn-lg back-btn"> <i class="fa fa-arrow-left"></i> Back</button>
                  </div>
                  <div class="col-md-6">
                      <button @click="navigateToCoverWizard" class="wizard-btn btn  btn-lg ">  Agree Terms &amp; Conditions <i class="fa fa-arrow-right"></i></button>
                  </div>
                </div>

              </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>

  export default {
    name: 'home',
    data () {
      return {

      };
    },

    methods:{
      goBackToHomepage(){
        this.$router.go(-1);
      },
      navigateToCoverWizard(){

        this.$router.push('/cover')
      }
    }
  }
</script>

<style>

  .wizard-btn{
    float: right;
  }

  .back-btn{
    float: left;
  }

  .wizard-btn,  .back-btn{
    background-color: #04131e;
    border:none;
    color:#D7FF1D;
    border-radius: 0px;
    font-weight: bolder;
  }
</style>
